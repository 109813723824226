export default {
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiplications"])},
    "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionne la ou les table<span aria-hidden=\"true\">(s)</span> que tu souhaites réviser&nbsp;:"])},
    "table": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Table de ", _interpolate(_named("table"))])},
    "table-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les tables"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])}
  },
  "multiplications": {
    "equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Égal ", _interpolate(_named("result"))])}
  }
}