export default {
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiplications"])},
    "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the table<span aria-hidden=\"true\">(s)</span> you would like to review:"])},
    "table": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Table of ", _interpolate(_named("table"))])},
    "table-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the tables"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])}
  },
  "multiplications": {
    "equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Equal ", _interpolate(_named("result"))])}
  }
}